import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "../assets/blond1.png"; // Import your image

const HeroContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: "50px",
  minHeight: "40vh",
  backgroundColor: "#171717",
  display: "flex",
  backgroundImage: `url(${backgroundImage})`, // Use imported image
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  [theme.breakpoints.up("sm")]: {
    height: "50vh",
  },
  [theme.breakpoints.up("md")]: {
    height: "60vh",
  },
  [theme.breakpoints.up("lg")]: {
    height: "80vh",
  },
}));

const HeaderText = styled(Box)(({ theme }) => ({
  color: "rgb(190, 191, 192)",
  position: "absolute",
  top: "10%",
  right: "7%",
}));

const Title = styled(Typography)(({ theme }) => ({
  letterSpacing: "2px",
  fontSize: "45px",
  lineHeight: "45px",
  marginLeft: "auto",
  fontFamily: "Arizonia, cursive",
  fontWeight: 400,
  fontStyle: "normal",
  textAlign: "center",
  borderBottom: "1px solid #535151",
  [theme.breakpoints.up("sm")]: {
    letterSpacing: "5px",
    fontSize: "55px",
    lineHeight: "5rem",
  },
  [theme.breakpoints.up("md")]: {
    letterSpacing: "8px",
    fontSize: "75px",
    lineHeight: "6rem",
  },
  [theme.breakpoints.up("lg")]: {
    letterSpacing: "6px",
    fontSize: "7rem",
    lineHeight: "10rem",
  },
}));

const gradientBackgroundStyle = {
  background: "linear-gradient(90deg, #333, #ccc)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const SmallText = styled(Typography)(({ theme }) => ({
  width: "15rem",
  textAlign: "center",
  // fontFamily: '"Montserrat", sans-serif',
  fontSize: "12px",
  lineHeight: "20px",
  letterSpacing: "1px",
  color: "aliceblue",
  marginLeft: "auto",
  marginTop: "1rem",

  [theme.breakpoints.up("sm")]: {
    width: "18rem",
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("md")]: {
    width: "24rem",
    lineHeight: "30px",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "35rem",
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "2.5px",
  },
}));

function Hero() {
  return (
    <HeroContainer id="home">
      <HeaderText>
        {/* <Title variant="h5" className="title">Beauty Space</Title> */}
        <Title variant="h5" className="title">
          <span style={gradientBackgroundStyle}>Team</span> <span>Ett</span>
        </Title>
        <SmallText className="small-text">
          Be Your Own Kind of Beautiful
        </SmallText>
      </HeaderText>
    </HeroContainer>
  );
}

export default Hero;

// import React from "react";
// import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// import { styled } from "@mui/system";
// import backgroundImage from "../assets/blond1.png"; // Import your image

// const HeroContainer = styled(Box)(({ theme }) => ({
//   position: "relative",
//   width: "100%",
//   height: "80vh",
//   display: "flex",
//   flexDirection: "row",
//   [theme.breakpoints.down("sm")]: {
//     flexDirection: "column",
//     height: "auto",
//   },
// }));
// const ContentColumn = styled(Box)(({ theme, isMobile }) => ({
//   width: isMobile ? "100%" : "50%",
//   padding: isMobile ? "20px" : "100px 0 30px 120px",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   backgroundColor: "rgba(255, 255, 255, 0.5)",
//   backdropFilter: "blur(5px)",
//   WebkitBackdropFilter: "blur(10px)",
//   borderRadius: "10px",
//   clipPath: isMobile
//     ? "none" // No clipping for content on mobile
//     : "polygon(10% 0, 100% 0, 100% 100%, 0 100%)", // Oblique for larger screens
//   marginTop: isMobile ? "-20px" : "0", // Overlap content under image
// }));
// const ImageColumn = styled(Box)(({ isMobile }) => ({
//   width: isMobile ? "100%" : "50%",
//   height: isMobile ? "40vh" : "100%",
//   backgroundImage: `url(${backgroundImage})`,
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "center",
//   clipPath: isMobile
//     ? "polygon(0 0, 100% 0, 100% 90%, 0 100%)" // ClipPath applied to bottom of image
//     : "polygon(10% 0, 100% 0, 100% 100%, 0 100%)", // Oblique for larger screens
// }));

// const Title = styled(Typography)(({ theme }) => ({
//   letterSpacing: "2px",
//   fontSize: "32px",
//   lineHeight: "45px",
//   fontFamily: "Arizonia, cursive",
//   fontWeight: 400,
//   fontStyle: "normal",
//   textAlign: "center",
//   color: "#171717",
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "45px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "60px",
//   },
// }));

// const SmallText = styled(Typography)(({ theme }) => ({
//   textAlign: "center",
//   fontSize: "14px",
//   lineHeight: "25px",
//   color: "#171717",
//   marginTop: "1rem",
//   [theme.breakpoints.up("md")]: {
//     fontSize: "16px",
//     lineHeight: "30px",
//   },
// }));

// function Hero() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <HeroContainer>
//       <ContentColumn isMobile={isMobile}>
//         <Title>
//           <span>Team</span>{" "}
//           <span style={{ color: "rgb(190, 191, 192)" }}>Ett</span>
//         </Title>
//         <SmallText>Be Your Own Kind of Beautiful</SmallText>
//       </ContentColumn>
//       <ImageColumn isMobile={isMobile} />
//     </HeroContainer>
//   );
// }

// export default Hero;
