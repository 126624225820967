import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import backgroundImage from "../assets/em.png";
import { Typography } from "@mui/material";

const TitleEmpty = styled(Typography)(({ theme }) => ({
  letterSpacing: "2px",
  fontSize: "55px",
  lineHeight: "45px",
  textAlign: "center",
  marginLeft: "auto",
  color: "rgb(255, 255, 255, 0.5)",
  fontFamily: "Arizonia, cursive", // Updated font family to Arizonia
  fontWeight: 400, // Updated font weight to 400
  fontStyle: "normal", // Ensure the font style is normal
  [theme.breakpoints.up("sm")]: {
    letterSpacing: "5px",
    fontSize: "75px",
    lineHeight: "5rem",
  },
  [theme.breakpoints.up("md")]: {
    letterSpacing: "8px",
    fontSize: "100px",
    lineHeight: "6rem",
  },
}));

const SmallTextEm = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontFamily: '"Montserrat", "sans-serif"',
  fontSize: "14px",
  lineHeight: "15px",
  letterSpacing: "1px",
  color: "rgb(255, 255, 255, 0.5)",
  margin: "auto",

  [theme.breakpoints.up("sm")]: {
    width: "18rem",
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("md")]: {
    width: "24rem",
    lineHeight: "30px",
    letterSpacing: "2px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "35rem",
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "2.5px",
  },
}));

const EmptyHome = () => {
  return (
    <BoxesIT>
      <Box className="emptyHome-container">
        <Box className="row bothText">
          <Box className="col-12 first-emptyText text-center">
            <TitleEmpty>Team Ett</TitleEmpty>
            <SmallTextEm className="small-text">
              Be Your Own Kind of Beautiful
            </SmallTextEm>
          </Box>
        </Box>
      </Box>
    </BoxesIT>
  );
};

const BoxesIT = styled(Box)({
  width: "100%",
  height: "40vh",
  backgroundImage: `url(${backgroundImage})`,
  textAlign: "center",
  backgroundColor: "black",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  fontFamily: "Montserrat, sans-serif",
  marginBottom: "5rem",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  color: "aliceblue",
});

export default EmptyHome;
