import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import bokadirektImage from "../assets/bokadirekt_left_400x200.png"; // Import the image

const StyledImage = styled("img")(({ theme }) => ({
  width: "200px", // Adjust width as needed
  height: "auto",
  marginBottom: "1rem",
  cursor: "pointer",
  borderRadius: "10px",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const Welcome = () => {
  return (
    <Box
      className="second-container"
      id="second"
      sx={{
        width: "90%",
        height: "auto",
        margin: "auto",
        marginTop: "20px",
        marginBottom: "20px",
        position: "relative",
      }}
    >
      <a
        href="https://www.bokadirekt.se/places/salong-team-ett-60828"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledImage src={bokadirektImage} alt="Bokadirekt" />
      </a>
      <Grid item xs={12} md={7} lg={8}>
        <div
          className="foto-text"
          sx={{
            height: "auto",
            backgroundColor: "#C4C4C4",
            marginTop: "1rem",
            fontFamily: '"Montserrat", sans-serif',
            "@media (min-width: 768px)": {
              marginTop: "0",
            },
          }}
        >
          <Typography
            variant="h3"
            className="welcome-text text-center"
            sx={{
              letterSpacing: "1px",
              fontSize: "16px",
              lineHeight: "30px",
              fontWeight: "500",
              fontFamily: '"Montserrat", sans-serif',
              "@media (min-width: 1024px)": {
                paddingTop: "1.5rem",
                letterSpacing: "1.5px",
                fontSize: "24px",
                width: "70%",
                margin: "auto",
              },
            }}
          >
            Varje dag öppnar vi dörrarna till vår skönhetssalong för att ge våra
            kunder nöjet att vara vackra!
          </Typography>
        </div>
      </Grid>
    </Box>
  );
};

export default Welcome;
