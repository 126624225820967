import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaInstagram, FaFacebook, FaLink } from "react-icons/fa";

const FooterContainer = styled(Box)({
  width: "100%",
  backgroundColor: "grey",
  color: "#d3d3d3",
  padding: "1rem 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const ContactDetails = styled(Box)({
  marginBottom: "1rem",
  textAlign: "center",
  lineHeight: "1.5",
});

const SocialLinks = styled(Box)({
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1rem",
});

const FooterLink = styled("a")({
  textDecoration: "none",
  color: "#d3d3d3",
  fontSize: "1.5rem",
});

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <ContactDetails>
        <Typography variant="body2">
          Address: Magnus Ladulåsgatan 61, 118 27 Stockholm
        </Typography>
        <Typography variant="body2">Phone: 073-653 09 10</Typography>
        <Typography variant="body2">
          Email:{" "}
          <a
            href="mailto:juliateamett@gmail.com"
            style={{ textDecoration: "none", color: "#d3d3d3" }}
          >
            juliateamett@gmail.com
          </a>
        </Typography>
      </ContactDetails>
      <SocialLinks>
        <FooterLink
          href="https://www.instagram.com/team_ett_stockholm/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </FooterLink>
        <FooterLink
          href="https://www.facebook.com/TeamEtt.Stockholm/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </FooterLink>
        <FooterLink
          href="https://www.bokadirekt.se/places/salong-team-ett-60828"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLink />
        </FooterLink>
      </SocialLinks>
      <Typography variant="body2" style={{ marginTop: "1rem" }}>
        <a
          href="https://samoteev.dev/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          Team Ett @ 2024
        </a>
      </Typography>
    </FooterContainer>
  );
};

export default Footer;
